import api from '../images/api.svg';
import param_user_input_icon from '../images/param_user_input.svg';
import param_query_icon from '../images/param_query.svg';
import param_list_icon from '../images/param_list.svg';
import param_api_icon from '../images/param_api.svg';
import param_matik_user_icon from '../images/logo.svg';
import content_type_dynamic_icon from '../images/content_type_dynamic.svg';
import content_type_static_icon from '../images/content_type_static.svg';
import content_type_conditional_icon from '../images/content_type_conditional.svg';
import content_type_ai_icon from '../images/content_type_ai.svg';
import presto_logo from '../images/presto.svg';
import productboard_logo from '../images/productboard_logo.svg';
import redshift_logo from '../images/redshift.svg';
import salesforce_logo from '../images/salesforce.svg';
import postgres_logo from '../images/postgres.svg';
import googlebq_logo from '../images/google_bigquery.svg';
import mysql_logo from '../images/mysql.svg';
import snowflake_logo from '../images/snowflake.svg';
import looker_logo from '../images/looker.svg';
import tableau_logo from '../images/tableau.svg';
import google_sheets_logo from '../images/google_sheets.svg';
import athena_logo from '../images/athena.svg';
import gainsight_logo from '../images/gainsight-16x16.svg';
import mssql_logo from '../images/mssql.svg';
import excel_logo from '../images/excel.svg';
import power_bi_logo from '../images/power_bi.svg';
import databricks_logo from '../images/databricks.svg';
import dynamic_content_text_icon from '../images/dynamic_content_text.svg';
import dynamic_content_chart_icon from '../images/dynamic_content_chart.svg';
import dynamic_content_table_icon from '../images/dynamic_content_table.svg';
import dynamic_content_image_icon from '../images/dynamic_content_image.svg';
import dynamic_content_contact_icon from '../images/dynamic_content_recipient.svg';
import dynamic_content_conditional_icon from '../images/dynamic_content_conditional.svg';
import dynamic_content_sender_icon from '../images/dynamic_content_sender.svg';
import hubspot_logo from '../images/hubspot.svg';
import google_slides_icon_sm from '../images/google_slides_icon_sm.svg';
import powerpoint_icon_sm from '../images/ppt_icon_sm.svg';
import wand_icon from '../images/icons/wand-16x16-regular.svg';
import static_icon from '../images/icons/static-16x16-regular.svg';
import conditional_icon from '../images/icons/conditional-16x16-regular.svg';
import icon_text from '../images/icons/text-16x16-regular.svg';
import icon_chart from '../images/icons/chart-16x16-regular.svg';
import icon_table from '../images/icons/table-16x16-regular.svg';
import icon_image from '../images/icons/image-16x16-regular.svg';
import icon_recipient from '../images/icons/recipient-16x16-regular.svg';
import icon_sender from '../images/icons/sender-16x16-regular.svg';

export default {
  InputTypes: {
    STRING: 'string',
    BOOLEAN: 'boolean',
    DATE: 'date',
    DATE_RANGE: 'date_range',
    LIST: 'list',
    DATE_TIME: 'datetime',
  },
  InputSources: { USER_INPUT: 'user_input', QUERY: 'query', LIST: 'list', API: 'api', MATIK_USER: 'matik_user' },
  InputIconsBySource: {
    USER_INPUT: 'input_text',
    QUERY: 'input_query',
    LIST: 'input_list',
    API: 'input_api',
    MATIK_USER: 'input_matik_user',
  },
  INPUT_TYPES_FOR_INPUT_SOURCE: {
    user_input: [
      { value: 'date', display: 'Date Picker' },
      { value: 'date_range', display: 'Date Range Picker' },
      { value: 'string', display: 'Text Box' },
      { value: 'boolean', display: 'Checkbox' },
      { value: 'list', display: 'Multi-select Dropdown' },
    ],
    query: [
      { value: 'string', display: 'Single-select Dropdown' },
      { value: 'list', display: 'Multi-select Dropdown' },
    ],
    list: [
      { value: 'string', display: 'Single-select Dropdown' },
      { value: 'list', display: 'Multi-select Dropdown' },
    ],
    api: [
      { value: 'string', display: 'Single-select Dropdown' },
      { value: 'list', display: 'Multi-select Dropdown' },
      { value: 'boolean', display: 'Checkbox' },
    ],
  },
  INPUT_TYPES_FOR_DISPLAY: {
    user_input: { icon: param_user_input_icon, text: 'User Input' },
    query: { icon: param_query_icon, text: 'Query' },
    list: { icon: param_list_icon, text: 'List' },
    api: { icon: param_api_icon, text: 'API' },
    matik_user: { icon: param_matik_user_icon, text: 'Matik User' },
  },
  INITIAL_VALUES_FOR_INPUT_TYPES: {
    string: '',
    boolean: false,
    date: null,
    date_range: [null, null],
    list: [],
  },
  MATIK_USER_INPUTS: {
    MatikUser_Email: 'MatikUser_Email',
    MatikUser_Name: 'MatikUser_Name',
    MatikUser_Phone: 'MatikUser_Phone',
    MatikUser_ProfileImage: 'MatikUser_Profile-Image',
    MatikUser_Title: 'MatikUser_Title',
  },
  INPUT_REGEX_PATTERN: /&:((?:\w|[-])+)/g,
  NESTED_CONTENT_REGEX_PATTERN: /\{\{((?:\w|[-])+)\}\}/g,
  MAX_VALUES_PER_FACET: 500, // Static cap on results returned from Algolia (default 20)
  MAX_SALESFORCE_REPORTS: 2000, // Max # of Salesforce Reports an account can have until they are switched to search query
  SALESFORCE_REPORT_CHARACTER_LIMIT: 255,
  ContentType: { DYNAMIC: 'dynamic', STATIC: 'static', INSIGHTS: 'insights', CONDITIONAL: 'conditional' },
  CONTENT_TYPE_DISPLAY: {
    dynamic: {
      icon: content_type_dynamic_icon,
      text: 'Data-Driven',
      description: 'Pull information from one of your Data Sources',
    },
    static: {
      icon: content_type_static_icon,
      text: 'Static',
      description: 'Preset the information to use',
    },
    insights: {
      icon: content_type_ai_icon,
      text: 'Insights',
      description: 'Let Matik create content for you',
    },
    conditional: {
      icon: content_type_conditional_icon,
      text: 'Conditional',
      description: 'Insert content based on conditions you define',
    },
  },
  DATA_SOURCE_DEFAULT_PORTS: {
    presto: '8080',
    hive: '10000',
    redshift: '5439',
    postgresql: '5432',
    mysql: '3306',
    mssql: '1433',
    databricks: '443',
  },
  DATA_SOURCE_TYPES: {
    presto: 'presto',
    redshift: 'redshift',
    productboard: 'productboard',
    salesforce: 'salesforce',
    postgresql: 'postgresql',
    mysql: 'mysql',
    googlebq: 'googlebq',
    snowflake: 'snowflake',
    matik_logos: 'matik_logos',
    looker: 'looker',
    tableau: 'tableau',
    api: 'api',
    google_sheet: 'google_sheet',
    matik_content: 'matik_content',
    athena: 'athena',
    databricks: 'databricks',
    hubspot: 'hubspot',
    gainsight: 'gainsight',
    mssql: 'mssql',
    excel: 'excel',
    power_bi: 'power_bi',
    matik_ai: 'matik_ai',
    matik_auto_insights: 'matik_auto_insights',
  },
  DATA_SOURCE_TYPE_DISPLAY: {
    presto: { icon: presto_logo, text: 'Presto', logoName: 'presto' },
    productboard: { icon: productboard_logo, text: 'Productboard', logoName: 'productboard' },
    redshift: { icon: redshift_logo, text: 'Redshift', logoName: 'redshift' },
    salesforce: { icon: salesforce_logo, text: 'Salesforce', logoName: 'salesforce' },
    postgresql: { icon: postgres_logo, text: 'PostgreSQL', logoName: 'postgres' },
    googlebq: { icon: googlebq_logo, text: 'Google BigQuery', logoName: 'big_query' },
    mysql: { icon: mysql_logo, text: 'MySQL', logoName: 'mysql' },
    snowflake: { icon: snowflake_logo, text: 'Snowflake', logoName: 'snowflake' },
    looker: { icon: looker_logo, text: 'Looker', logoName: 'looker' },
    tableau: { icon: tableau_logo, text: 'Tableau', logoName: 'tableau' },
    api: { icon: api, text: 'REST API', logoName: 'api' },
    google_sheet: { icon: google_sheets_logo, text: 'Google Sheet', logoName: 'google_sheets' },
    matik_logos: { icon: api, text: 'Matik Logos', logoName: 'api' },
    athena: { icon: athena_logo, text: 'Athena', logoName: 'athena' },
    databricks: { icon: databricks_logo, text: 'Databricks', logoName: 'databricks' },
    hubspot: { icon: hubspot_logo, text: 'HubSpot', logoName: 'hubspot' },
    gainsight: { icon: gainsight_logo, text: 'Gainsight', logoName: 'gainsight' },
    mssql: { icon: mssql_logo, text: 'MS SQL Server/Azure', logoName: 'mssql' },
    excel: { icon: excel_logo, text: 'Excel', logoName: 'excel' },
    power_bi: { icon: power_bi_logo, text: 'Power BI', logoName: 'power_bi' },
    matik_ai: { icon: wand_icon, text: 'Matik AI', logoName: 'ai' },
    matik_auto_insights: { icon: wand_icon, text: 'Matik Automated Insights', logoName: 'ai' },
    consumer_input: { icon: conditional_icon, text: 'Conditional', logoName: 'conditional' },
    conditional: { icon: conditional_icon, text: 'Conditional', logoName: 'conditional' },
    static: { icon: static_icon, text: 'Static', logoName: 'static' },
  },
  DATA_SOURCE_TYPES_FOR_METHOD: {
    query: [
      'presto',
      'redshift',
      'salesforce',
      'postgresql',
      'mysql',
      'googlebq',
      'snowflake',
      'athena',
      'databricks',
      'mssql',
    ],
    api: [
      'salesforce',
      'matik_logos',
      'looker',
      'tableau',
      'api',
      'google_sheet',
      'hubspot',
      'gainsight',
      'productboard',
      'excel',
      'power_bi',
    ],
    consumer_input: [],
    static: ['matik_content'],
    insights: ['matik_ai', 'matik_auto_insights'],
  },
  DATA_SOURCE_CATEGORIES: {
    app: {
      title: 'Apps',
      dataSources: [
        'salesforce',
        'matik_logos',
        'looker',
        'tableau',
        'api',
        'hubspot',
        'gainsight',
        'productboard',
        'matik_ai',
        'power_bi',
      ],
    },
    data_warehouse: {
      title: 'Data Warehouses',
      dataSources: ['presto', 'redshift', 'googlebq', 'snowflake', 'athena', 'databricks'],
    },
    spreadsheet: {
      title: 'Spreadsheets',
      dataSources: ['google_sheet', 'excel'],
    },
    database: {
      title: 'Databases',
      dataSources: ['postgresql', 'mysql', 'mssql'],
    },
    static: {
      title: 'Static',
      dataSources: ['matik_content'],
    },
  },
  DATA_SOURCE_MATIK_CONTENT_ID: -2,
  DynamicContentTypes: {
    TEXT: 'text',
    CHART: 'chart',
    TABLE: 'table',
    IMAGE: 'image',
    CONDITIONAL: 'conditional',
    RECIPIENTS: 'recipients',
    SENDER: 'sender',
  },
  DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE: {
    matik_ai: ['text', 'image'],
    matik_auto_insights: ['text'],
    matik_logos: ['image'],
  },
  DYNAMIC_CONTENT_TYPE_DISPLAY: {
    text: { icon: dynamic_content_text_icon, text: 'Text', iconName: 'text' },
    chart: { icon: dynamic_content_chart_icon, text: 'Chart', iconName: 'chart' },
    table: { icon: dynamic_content_table_icon, text: 'Table', iconName: 'table' },
    image: { icon: dynamic_content_image_icon, text: 'Image', iconName: 'image' },
    conditional: { icon: dynamic_content_conditional_icon, text: 'Conditional', iconName: 'conditional' },
    recipients: { icon: dynamic_content_contact_icon, text: 'Recipients', iconName: 'recipient' },
    insights: { icon: dynamic_content_text_icon, text: 'Insights', iconName: 'ai' },
    sender: { icon: dynamic_content_sender_icon, text: 'Sender', iconName: 'sender' },
  },
  DYNAMIC_CONTENT_TYPE_LIST_DISPLAY: {
    text: { icon: icon_text, text: 'Text', iconName: 'text' },
    chart: { icon: icon_chart, text: 'Chart', iconName: 'chart' },
    table: { icon: icon_table, text: 'Table', iconName: 'table' },
    image: { icon: icon_image, text: 'Image', iconName: 'image' },
    conditional: { icon: conditional_icon, text: 'Conditional', iconName: 'conditional' },
    recipients: { icon: icon_recipient, text: 'Recipients', iconName: 'recipient' },
    insights: { icon: wand_icon, text: 'Insights', iconName: 'ai' },
    sender: { icon: icon_sender, text: 'Sender', iconName: 'sender' },
  },
  DynamicContentMethods: {
    CONSUMER_INPUT: 'consumer_input',
    QUERY: 'query',
    API: 'api',
    STATIC: 'static',
    INSIGHTS: 'insights',
  },
  DYNAMIC_CONTENT_METHOD_DISPLAY: {
    consumer_input: { text: 'Consumer Input', iconName: 'static' },
    query: { text: 'Query', iconName: 'data_driven' },
    api: { text: 'API', iconName: 'data_driven' },
    static: { text: 'Static', iconName: 'static' },
    insights: { text: 'Insights', iconName: 'wand' },
  },
  TEMPLATE_SOURCE_TYPES: {
    POWERPOINT: 'powerpoint',
    POWERPOINT_365: 'powerpoint_365',
    WORD: 'word',
    WORD_365: 'word_365',
    GOOGLE_SLIDES: 'google_slides',
    GOOGLE_DOCS: 'google_docs',
    GOOGLE_SHEETS: 'google_sheets',
    EMAIL: 'email',
    ALL_TYPES: ['powerpoint', 'powerpoint_365', 'google_slides', 'google_docs', 'word', 'word_365', 'email'],
    DOCUMENT_TYPES: ['word', 'word_365', 'google_docs'],
    PRESENTATION_TYPES: ['powerpoint', 'powerpoint_365', 'google_slides'],
    SPREADSHEET_TYPES: ['google_sheets'],
  },
  SUPPORTED_OPERATORS_BY_DATA_SOURCE: {
    conditional: ['=', '!=', '<', '>', '<=', '>=', 'contains', 'does not contain', 'in', 'not in'],
    conditions: [
      '=',
      '!=',
      '<',
      '>',
      '<=',
      '>=',
      'contains',
      'does not contain',
      'in',
      'not in',
      'is null',
      'is not null',
    ],
    looker: ['=', '!=', '<', '>', '<=', '>='],
    tableau: ['='],
    salesforce_objects: ['=', '!=', '<', '>', '>=', '<=', 'IN', 'NOT IN', 'LIKE', 'BETWEEN'],
    salesforce_reports: [
      'equals',
      'notEqual',
      'lessThan',
      'greaterThan',
      'lessOrEqual',
      'greaterOrEqual',
      'contains',
      'notContain',
      'startsWith',
      'includes',
      'excludes',
    ],
    hubspot_objects: [
      'equal',
      'not equal',
      'less than',
      'less or equal',
      'greater than',
      'greater or equal',
      'between',
      'in',
      'not in',
      'has property',
      'not has property',
    ],
    gainsight: {
      equals: 'EQ',
      'not equals': 'NE',
      'less than': 'LT',
      'greater than': 'GT',
      'less or equal': 'LTE',
      'greater or equal': 'GTE',
      between: 'BTW',
      contains: 'CONTAINS',
      'is null': 'IS_NULL',
      'is not null': 'IS_NOT_NULL',
      'does not contain': 'DOES_NOT_CONTAINS',
      'starts with': 'STARTS_WITH',
      'ends with': 'ENDS_WITH',
      includes: 'IN',
      excludes: 'NOT_IN',
    },
  },
  PRODUCER_ROLE: 'producer',
  CONSUMER_ROLE: 'consumer',
  PRODUCER_ADMIN_ROLE: 'producer_admin',
  SUPERADMIN_ROLE: 'superadmin',
  DATA_ADMIN_ROLE: 'data_admin',
  ROLE_GROUP_ACCESS_MAPPING: { '-2': 'All Admins', '-1': 'All End Users', '-3': 'All Producer Admins' },
  ROLE_ID_MAPPING: { producer: '-2', consumer: '-1' },
  ROLE_DISPLAY_MAPPING: {
    producer: 'Admin',
    consumer: 'End User',
    producer_admin: 'Producer Admin',
    superadmin: 'Super Admin',
    data_admin: 'Data Admin',
  },
  PERMISSIONS: {
    read: { value: 'read', longLabel: 'Add with read access', label: 'Can Read' },
    edit: { value: 'edit', longLabel: 'Add with edit access', label: 'Can Edit' },
    owner: { value: 'owner', label: 'Owner' },
  },
  ItemTypes: {
    INPUT: 'input',
    TEMPLATE: 'template',
    DYNAMIC_CONTENT: 'dynamic_content',
    PRESENTATION: 'presentation',
  },
  AVATAR_COLORS: [
    '#84B6F1',
    '#4280E5',
    '#205EBB',
    '#000080',
    '#FF1493',
    '#DF3C71',
    '#B2305B',
    '#49D492',
    '#227C56',
    '#008080',
    '#D2691E',
    '#F69D00',
    '#7B61FF',
    '#3D2D8F',
    '#4B0082',
  ],
  PAGE_SIZE: 50,
  DYNAMIC_CONTENT_PAGE_SIZE: 100,
  PANEL_PAGE_SIZE: 10,
  SUBSCRIPTION_STATUSES: {
    trialing: 'trialing',
    active: 'active',
    incomplete: 'incomplete',
    incomplete_expired: 'incomplete_expired',
    past_due: 'past_due',
    canceled: 'canceled',
    unpaid: 'unpaid',
  },
  // TODO(Sean): Always lookup prices dynamically via Stripe?
  MATIK_TIERS: {
    matik_team: {
      tier_id: 'matik_team',
      display_name: 'Team',
      monthly_price: '50',
      annual_price: '3,000',
      userLimit: '5',
    },
    matik_enterprise: {
      tier_id: 'matik_enterprise',
      display_name: 'Enterprise',
      monthly_price: 'Contact sales',
      annual_price: 'Contact sales',
    },
    matik_chrome_extension: {
      tier_id: 'matik_chrome_extension',
      display_name: 'Add On',
      monthly_price: '10',
      annual_price: '500',
      userLimit: '1',
    },
  },
  FREE_TRIAL_DAYS: 3,
  MONTHLY_PRESENTATIONS: 5,
  SALES_MAILTO_LINK:
    'mailto:sales@matik.io' +
    '?subject=Matik Enterprise' +
    '&body=Hi,%0D%0A%0D%0AI would like to learn more about what we can do with Matik Enterprise.' +
    ' Could you tell me more?%0D%0A%0D%0AThank you',
  USER_INTEGRATION_TYPES: {
    google: 'google',
    sendgrid: 'sendgrid',
    microsoft: 'microsoft',
  },
  TEMPLATES_SIDEBAR_MENU_CHOICES: {
    all_templates: 'all_templates',
    top_templates: 'top_templates',
    recent_templates: 'recent_templates',
    favorites: 'favorites',
    new_for_me: 'new_for_me',
    archived_templates: 'archived_templates',
  },
  TEMPLATE_CARD_DESCRIPTION_LENGTH: 135,
  DATE_FORMATS: {
    usa: 'MM/DD/YYYY',
    east_asia: 'YYYY/MM/DD',
    default: 'DD/MM/YYYY', // we are assuming that most of the world follows this date format
  },
  GOOGLE_AUTH: {
    base_url: 'https://accounts.google.com/o/oauth2/v2/auth',
  },
  MICROSOFT: {
    common_base_url: 'https://common-my.sharepoint.com',
    personal_base_url: 'https://onedrive.live.com',
    auth_base_url: 'https://login.microsoftonline.com',
    login_message: 'MATIK_MS_LOGIN_FINISHED',
    powerpoint_type: 'O365_PPT_FILE_TYPE',
    excel_type: 'O365_XLS_FILE_TYPE',
    word_type: 'O365_DOC_FILE_TYPE',
  },
  UI_TYPES: {
    modal: 'modal',
    sidepane: 'sidepane',
    googleSheetDrawer: 'googleSheetDrawer',
    editableField: 'editableField',
    dynamic_content: 'dynamic_content',
    input: 'input',
  },
  Tabs: {
    ANALYTICS: 'analytics',
    DATA_SOURCES: 'data_sources',
    DYNAMIC_CONTENT: 'dynamic_content',
    EMAIL: 'email',
    ENTERPRISE_SETTINGS: 'enterprise_settings',
    HOME: 'home',
    INPUTS: 'inputs',
    GENERATED: 'generated',
    FLOWS: 'flows',
    SCHEDULED: 'scheduled',
    TEMPLATES: 'templates',
    USER_PROFILE: 'user_profile',
    WELCOME: 'welcome',
    BRANDING_INFO: 'branding_info',
  },
  SUPPORTED_DATE_LITERALS_BY_DATASOURCE: {
    salesforce: [
      'YESTERDAY',
      'TODAY',
      'TOMORROW',
      'LAST_WEEK',
      'THIS_WEEK',
      'NEXT_WEEK',
      'LAST_MONTH',
      'THIS_MONTH',
      'NEXT_MONTH',
      'LAST_90_DAYS',
      'NEXT_90_DAYS',
      'LAST_N_DAYS',
      'NEXT_N_DAYS',
      'N_DAYS_AGO',
      'NEXT_N_WEEKS',
      'LAST_N_WEEKS',
      'N_WEEKS_AGO',
      'NEXT_N_MONTHS',
      'LAST_N_MONTHS',
      'N_MONTHS_AGO',
      'THIS_QUARTER',
      'LAST_QUARTER',
      'NEXT_QUARTER',
      'NEXT_N_QUARTERS',
      'LAST_N_QUARTERS',
      'N_QUARTERS_AGO',
      'THIS_YEAR',
      'LAST_YEAR',
      'NEXT_YEAR',
      'NEXT_N_YEARS',
      'LAST_N_YEARS',
      'N_YEARS_AGO',
      'THIS_FISCAL_QUARTER',
      'LAST_FISCAL_QUARTER',
      'NEXT_FISCAL_QUARTER',
      'NEXT_N_FISCAL_QUARTERS',
      'LAST_N_FISCAL_QUARTERS',
      'N_FISCAL_QUARTERS_AGO',
      'THIS_FISCAL_YEAR',
      'LAST_FISCAL_YEAR',
      'NEXT_FISCAL_YEAR',
      'NEXT_N_FISCAL_YEARS',
      'LAST_N_FISCAL_YEARS',
      'N_FISCAL_YEARS_AGO',
    ],
  },
  LOG_LEVEL: {
    info: 'INFO',
    warning: 'WARNING',
    error: 'ERROR',
  },
  SSO_TYPE: {
    okta: 'okta',
    azure: 'azure',
    onelogin: 'onelogin',
    google: 'google',
    generic: 'generic',
  },
  TemplateLibraryInfo: {
    qbr_template: {
      template_id: '1R20-7Om8tCRLIDdy5-fq2eEB96D4iWrwoLZOrWf1Oiw',
      powerpoint_template_id: 'QBR-Template-deck',
      description:
        'A best-in-class business review template that includes a team slide, executive summary, and several\n' +
        'different data visualizations.',
      accent_one: 'Used for Headlines, Icons, Chart Accents',
      accent_two: 'Used for Key Stats, Highlight Badges, Headline Decoration, Chart Accents',
      generation_text: 'Generating your QBR Template from the Matik Template Library...',
      template_title: 'QBR Template',
      narrative_type: 'PRESENTATION',
    },
    pricing_template: {
      template_id: '1u2L9Ihy9naIRymQ7i0E_0VMbsCJCB-7juyLOMimTsCs',
      powerpoint_template_id: 'pricing-proposal',
      description:
        'A robust set of pricing slides showing a variety of ways to communicate terms to your customers and prospects.',
      accent_one: 'Used for Headlines, Box & Table Headlines, Badges, Highlighted Columns, Icons',
      accent_two: 'Used for List Decoration, Headline Decoration, Badges on Highlighted Columns',
      generation_text: 'Generating your Pricing Template from the Matik Template Library...',
      template_title: 'Pricing Template',
      narrative_type: 'PRESENTATION',
    },
    qbr_one_pager: {
      template_id: '1LpYihbo36puAhyEqD5yp6GBfMmyYIOsRrL7aJ_W9ILI',
      powerpoint_template_id: 'QBR-Onepager',
      description: 'A streamlined business review inforgraphic intended to highlight key takeaways and insights.',
      accent_one: 'Used for  Headlines, Icons, Box Backgrounds (dark)',
      accent_two: 'Used for Key Stats, Headline Decoration, Highlight Values',
      generation_text: 'Generating your QBR one pager from the Matik Template Library...',
      template_title: 'QBR One Pager',
      narrative_type: 'PRESENTATION',
    },
  },

  useSample: {
    template_id: '1_5-SomLfYwqTRpreOQrBNhXSWwx6TLuZ9bQvQ6kCJYU',
  },

  TemplateLibrarySourceLabels: {
    googleSlides: {
      dropdown: { label: 'Google Slides', value: 0, icon: google_slides_icon_sm },
      endpoint: 'google_slides',
    },
    powerPoint: { dropdown: { label: 'PowerPoint', value: 1, icon: powerpoint_icon_sm }, endpoint: 'powerpoint' },
  },

  SendgridStatuses: {
    BOUNCE: 'bounce',
    PROCESSING: 'processing',
    PROCESSED: 'processed',
    DROPPED: 'dropped',
    UNSENT: 'unsent',
    DELIVERED: 'delivered',
    DEFERRED: 'deferred',
  },
  DEFAULT_DATE_FORMAT: 'yyyy-mm-dd-hh:mm:ss',
  VALID_DATE_FORMATS: [
    'mm/dd/yyyy',
    'mm/dd/yy',
    'mm/dd',
    'dd/mm/yyyy',
    'dd/mm/yy',
    'dd/mm',
    'dd-mm-yyyy',
    'dd-mm-yy',
    'dd-mm',
    'mm-dd-yyyy',
    'mm-dd-yy',
    'mm-dd',
    'yyyy-mm-dd',
    'm/d/yyyy',
    'm/d/yy',
    'm/d',
    'd/m/yyyy',
    'd/m/yy',
    'd/m',
    'm-d-yyyy',
    'm-d-yy',
    'm-d',
    'd-m-yyyy',
    'd-m-yy',
    'd-m',
    'Mth d, yyyy',
    'Mth d',
    'Month d, yyyy',
    'Month d',
    'hh:mm:ss',
    'yyyy-mm-dd HH:MM:SS tt',
    'yyyy-mm-dd-HH:MM:SS tt',
    'yyyy-mm-dd-hh:mm:ss',
    'yyyy-mm-dd hh:mm:ss',
  ],
  RecipientFieldTypes: {
    DYNAMIC_BULKED: 'dynamic_bulked',
    DYNAMIC_NOT_BULKED: 'dynamic_not_bulked',
    STATIC: 'static',
  },
  ConditionTargetType: {
    SLIDE: 'slide',
    TEMPLATE: 'template',
  },
  GenerationSettingsTabs: {
    FOLDER: {
      label: 'Folder',
      icon: 'folder',
    },
    NAMING_STRUCTURE: {
      label: 'Naming Structure',
      icon: 'rename',
    },
    CONDITIONS: {
      label: 'Conditions',
      icon: 'condition_arrow',
    },
  },
  NULL_VALUE_INPUT_LABEL: 'No Value',
  IntegrationFolderTypes: {
    USER: 'user',
    GROUP: 'group',
    TEMPLATE: 'template',
    ENTERPRISE: 'enterprise',
  },
  NEW_CONTENT_ID: 'new',
};
