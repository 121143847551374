import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import API from 'lib/api';
import utils from 'lib/utils';
import Constants from 'components/Constants';
import csv_icon from 'images/csv_icon.svg';
import { MAlert } from 'components/shared/Alerts';
import FileUpload from 'components/shared/fileUpload/FileUpload';
import UploadedFileBanner from 'components/shared/fileUpload/UploadedFileBanner';
import Modal from 'components/shared/modal/Modal';
import { closeModal, openModal } from 'redux/ui/action';
import { Form, Level } from 'react-bulma-components';
import ButtonGroup from 'components/lib/ButtonGroup';
import Button from 'components/lib/Button';
import PopupMenu from 'components/shared/PopupMenu';
import GeneratePresentationPopup from 'components/shared/GeneratePresentationPopup';

function CsvBulkUploadForm({ modalName, template, isReadOnly }) {
  const LDClient = useLDClient();
  const enableGenerationPopup = LDClient?.variation('enable-generation-popup', false);

  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvTaskId, setCsvTaskId] = useState('');

  const [includePdf, setIncludePdf] = useState(false);
  const [customName, setCustomName] = useState('');
  const [customFolder, setCustomFolder] = useState({});
  const [customFolderPlaceholder, setCustomFolderPlaceholder] = useState('/Matik Presentations/');
  const popupMenuRef = useRef();

  useEffect(() => {
    utils.getDefaultIntegrationFolderName(template?.id, (response) => {
      if (response?.length > 0) {
        setCustomFolderPlaceholder(response);
      }
    });
  }, []);

  const modalTitle = 'Bulk generation from a CSV';

  const onClose = () => {
    dispatch(closeModal());
  };

  const handleSelectFile = (files) => {
    setSelectedFile(files[0]);
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    setCsvTaskId('');
  };

  const uploadFile = (e, shouldSchedule = false) => {
    e.preventDefault();
    if (!selectedFile) {
      return MAlert('Select a CSV file to upload', 'Error', 'error');
    }
    setIsLoading(true);
    API.post(
      `/templates/${template.id}/csv/`,
      {
        is_scheduled: shouldSchedule,
        include_pdf: includePdf,
        custom_folder: customFolder,
        custom_name: customName,
      },
      (response) => {
        setIsLoading(false);
        onClose();
        if (shouldSchedule) {
          setCsvTaskId(response.data.csv_task_id);
          dispatch(openModal('scheduledFlowModal', response.data.csv_task_id));
        } else {
          utils.notify("Started bulk generation. We'll email you once it's done!");
        }
      },
      (err) => {
        setIsLoading(false);
        API.defaultError(err);
      },
      selectedFile,
    );
  };

  const openScheduledFlowModal = (e) => {
    if (csvTaskId) {
      dispatch(openModal('scheduledFlowModal', csvTaskId));
    } else {
      uploadFile(e, true);
    }
  };

  let templateType;
  let templateTypeCopy;
  switch (template.source_type) {
    case Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS:
    case Constants.TEMPLATE_SOURCE_TYPES.WORD:
    case Constants.TEMPLATE_SOURCE_TYPES.WORD_365:
      templateType = 'document';
      templateTypeCopy = 'a document to generate';
      break;
    case Constants.TEMPLATE_SOURCE_TYPES.EMAIL:
      templateType = 'email';
      templateTypeCopy = 'an email to send';
      break;
    case Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS:
      templateType = 'spreadsheet';
      templateTypeCopy = 'a spreadsheet to generate';
      break;
    default:
      templateType = 'presentation';
      templateTypeCopy = 'a presentation to generate';
  }

  const body = (
    <div className="has-text-centered">
      <p className="mbm">
        {`Your CSV should have a column for each input where each column name matches the input name and each row is ${templateTypeCopy}. It should contain a maximum of 15000 rows.`}
        &nbsp;
        <a href="https://help.matik.io/hc/en-us/articles/11822509271451-Bulk-Generating-presentations-with-a-CSV-file-of-inputs">
          Learn more
        </a>
      </p>
      <FileUpload buttonText="Select CSV from your computer" onSelectFile={handleSelectFile} inputAccept=".csv" />
      {selectedFile && (
        <UploadedFileBanner
          onCancel={clearSelectedFile}
          uploadedFileName={selectedFile.name}
          uploadedIconSrc={csv_icon}
        />
      )}
    </div>
  );

  const disabledFolderOptions = [
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS,
  ].includes(template.source_type)
    ? ['microsoft']
    : ['google'];

  const namePreview = utils.getNameTemplatePreview(template);

  const onPdfToggle = (e) => {
    API.track('toggle_include_pdf', { include_pdf: e.target.checked });
    setIncludePdf(e.target.checked);
  };

  const onCustomFolderUpdate = (result) => {
    if (!result || Object.keys(result).length === 0) {
      API.track('remove_run_custom_folder');
      setCustomFolder({});
      return;
    }
    const folder = {
      ...result,
      folder_type: 'template',
      template_id: template.id,
    };
    API.track('set_run_custom_folder', { folder: folder });
    setCustomFolder(folder);
  };

  const onCustomNameUpdate = (name) => {
    API.track('edit_presentation_name', { name: name });
    setCustomName(name);
  };

  const getStatus = () => {
    if (!selectedFile) {
      return 'disabled';
    } else if (isLoading) {
      return 'loading';
    } else {
      return 'default';
    }
  };

  const endUserGenerateButton = (
    <Button
      category="primary"
      type="button"
      onClick={() => {
        API.track('open_generate_popup');
      }}
      status={getStatus()}
    >
      {templateType === 'email' ? 'Send Immediately' : 'Generate'}
    </Button>
  );
  const generateButton = enableGenerationPopup ? (
    <PopupMenu ref={popupMenuRef} trigger={endUserGenerateButton} offset={-93} position="x">
      <GeneratePresentationPopup
        onPdfToggle={onPdfToggle}
        includePdf={includePdf}
        templateType={templateType}
        sourceType={template.source_type}
        popupMenuRef={popupMenuRef}
        showConditionsBanner={Boolean(template?.conditions?.length)}
        primaryButtonOnClick={uploadFile}
        primaryButtonText={templateType === 'email' ? 'Send' : 'Generate'}
        primaryButtonActive={true}
        onCustomFolderUpdate={onCustomFolderUpdate}
        customFolder={customFolder}
        customFolderPlaceholder={customFolderPlaceholder}
        onCustomNameUpdate={onCustomNameUpdate}
        customName={customName}
        disabledFolderOptions={disabledFolderOptions}
        namePreview={namePreview}
      />
    </PopupMenu>
  ) : (
    <Button
      category="primary"
      status={!selectedFile ? 'disabled' : isLoading ? 'loading' : 'default'}
      onClick={uploadFile}
    >
      {templateType === 'email' ? 'Send Immediately' : 'Generate Immediately'}
    </Button>
  );

  let buttons;
  if (!isReadOnly) {
    buttons = (
      <Form.Field className="fixed-bottom-button">
        <Form.Control className="block-button">
          <Level className="!justify-end">
            <Level.Side align="right">
              <Level.Item>
                <ButtonGroup>
                  <Button
                    category="secondary"
                    status={!selectedFile ? 'disabled' : isLoading ? 'loading' : 'default'}
                    onClick={openScheduledFlowModal}
                  >
                    {templateType === 'email' ? 'Schedule' : 'Schedule Generation'}
                  </Button>
                  {generateButton}
                </ButtonGroup>
              </Level.Item>
            </Level.Side>
          </Level>
        </Form.Control>
      </Form.Field>
    );
  }

  return modalName ? (
    <Modal
      show={ui.modal?.name === modalName}
      title={modalTitle}
      onClose={onClose}
      primaryButtonText="Generate Immediately"
      primaryButtonOnClick={uploadFile}
      primaryButtonDisabled={!selectedFile}
      primaryButtonLoading={isLoading}
      secondaryButtonText={'Schedule Generation'}
      secondaryButtonOnClick={openScheduledFlowModal}
      secondaryButtonDisabled={!selectedFile}
      secondaryButtonLoading={isLoading}
      tertiaryButtonText="Cancel"
      tertiaryButtonOnClick={onClose}
      showDefaultFooter={true}
    >
      {body}
    </Modal>
  ) : (
    <div className="p-5">
      {body}
      {buttons}
    </div>
  );
}

CsvBulkUploadForm.propTypes = {
  modalName: PropTypes.string,
  template: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
};

export default CsvBulkUploadForm;
