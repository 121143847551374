import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bulma-components';
import { ReactComponent as PptIcon } from 'images/ppt_icon.svg';
import microsoft365_drive_logo from 'images/microsoft365_drive_logo.png';
import loading_animation from 'images/loading.gif';
import Icon from 'components/lib/Icon';
import DropdownMenu from '../../shared/DropdownMenu';
import Button from '../../lib/Button';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { MConfirmHtml } from '../../shared/Alerts';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import { openFullScreenModal } from 'redux/ui/action';
import GenerationSettingsModal from './GenerationSettingsModal';

function TemplateActionDropdown({
  setIsLibrary,
  canEdit,
  syncTemplate,
  onTemplateDelete,
  onTemplateArchive,
  convertTemplate,
  containsLibrarySlide,
  currentTemplate,
  orderedInputs,
  onTemplateUpdateWithServerCall,
}) {
  const [active, setActive] = useState(false);
  const [linkIsLoading, setLinkIsLoading] = useState(false);
  const pickerRef = useRef(null);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const onTemplateDropdownAction = (e) => {
    e.preventDefault();
    const value = e.target.id ? e.target.id : e.target.parentNode.id;
    if (value === 'delete' && canEdit) {
      onTemplateDelete(currentTemplate);
    } else if (value === 'sync' && canEdit) {
      syncTemplate();
    } else if (value === 'open') {
      let url = currentTemplate.source.url;
      if (
        currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        setLinkIsLoading(true);
        utils.openTemplateFileInNewTab(e, currentTemplate, () => {
          setLinkIsLoading(false);
        });
        return;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    } else if (value === 'library' && canEdit) {
      setIsLibrary(!currentTemplate.is_library);
    } else if (value === 'archive' && canEdit) {
      onTemplateArchive(currentTemplate);
    } else if (value === 'convert') {
      MConfirmHtml(
        'Convert Template To Microsoft 365',
        'Select the folder where you would like your PowerPoint template to be saved. Matik will upload a copy of your PowerPoint to this folder and then sync this template to the cloud-hosted file. This cannot be undone.',
        'info',
        (confirmed) => {
          if (confirmed) {
            pickerRef.current?.click();
          }
        },
        'Select Folder',
        'Cancel',
        null,
        'no-perms-alert',
      );
    } else if (value === 'settings') {
      dispatch(openFullScreenModal('generationSettingsModal'));
    }
  };

  const handle365SelectFolder = ({ items }) => {
    convertTemplate(items[0]);
  };

  const isPublishable =
    canEdit &&
    !Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(currentTemplate.source_type) &&
    !currentTemplate.is_sub_template &&
    (!containsLibrarySlide || (containsLibrarySlide && currentTemplate.is_library));

  const openIcon =
    currentTemplate.source_type === 'powerpoint_365' ? (
      linkIsLoading ? (
        <img className="w-5 h-5 m-0 p-0 border-0 bg-transparent" src={loading_animation}></img>
      ) : (
        <PptIcon className="m-0 p-0 icon-pull-down icon-size-20" />
      )
    ) : (
      <Icon name="open" size={20} />
    );

  return (
    <>
      <DropdownMenu
        dropdownTrigger={
          <Button category="secondary" onClick={() => setActive(!active)}>
            <Icon name="more_horizontal" size={20} />
          </Button>
        }
        isHoverable={false}
        isRight={true}
        active={active}
        onClose={() => setActive(false)}
      >
        <a id="open" href="" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
          {openIcon}
          {currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ? (
            <span className="ml-2">Open in Google Slides</span>
          ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ? (
            <span className="ml-2">Open in Google Docs</span>
          ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS ? (
            <span className="ml-2">Open in Google Sheets</span>
          ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365 ? (
            <span className="ml-2">Open in Word</span>
          ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD ? (
            <span className="ml-2">Download Word</span>
          ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT ? (
            <span className="ml-2">Open in PowerPoint</span>
          ) : (
            <span className="ml-2">Download PowerPoint</span>
          )}
        </a>
        {canEdit && !currentTemplate.is_sub_template && (
          <a id="sync" href="" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
            <Icon name="arrow_sync_circle" size={20} />
            {currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
            currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
            currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS ? (
              <span className="ml-2">Sync from Google Drive</span>
            ) : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
              currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365 ? (
              <span className="ml-2">Sync from Microsoft 365</span>
            ) : (
              <span className="ml-2">Sync Template</span>
            )}
          </a>
        )}
        {canEdit && (
          <a
            id="archive"
            href=""
            className="dropdown-item mbs"
            onClick={onTemplateDropdownAction}
            data-tooltip-id="matik-tooltip"
            data-tooltip-content="Archive this template to hide it from end users."
          >
            <Icon name="archive" size={20} />
            <span className="ml-2">Archive Template</span>
          </a>
        )}
        {canEdit && (
          <a
            id="settings"
            href=""
            className="dropdown-item"
            onClick={onTemplateDropdownAction}
            data-tooltip-id="matik-tooltip"
          >
            <Icon name="settings" size={20} />
            <span className="ml-2">Generation Settings</span>
          </a>
        )}
        {isPublishable && (
          <>
            <Dropdown.Divider />
            <a id="library" href="" className="dropdown-item" onClick={onTemplateDropdownAction}>
              <Icon name="library" size={20} />
              {currentTemplate.is_library ? (
                <span className="ml-2">Unpublish as Library</span>
              ) : (
                <span className="ml-2">Publish as Library</span>
              )}
            </a>
          </>
        )}
        {canEdit && (
          <>
            <Dropdown.Divider />
            {currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT && (
              <>
                <a id="convert" href="" className="dropdown-item mbs" onClick={onTemplateDropdownAction}>
                  <img
                    src={microsoft365_drive_logo}
                    className="w-5 h-5 m-0 p-0 border-0"
                    alt="Convert PowerPoint"
                  ></img>
                  <span className="ml-2">Convert to Microsoft 365</span>
                </a>
                <MicrosoftPicker
                  className="hidden"
                  onPicked={handle365SelectFolder}
                  pickerMode="folders"
                  buttonRef={pickerRef}
                />
              </>
            )}
            <a id="delete" href="" className="dropdown-item" onClick={onTemplateDropdownAction}>
              <Icon name="trash_can" size={20} />
              <span className="ml-2">Delete Template</span>
            </a>
          </>
        )}
      </DropdownMenu>
      <GenerationSettingsModal
        show={ui.fullScreenModal?.name === 'generationSettingsModal'}
        currentTemplate={currentTemplate}
        orderedInputs={orderedInputs}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
      />
    </>
  );
}

TemplateActionDropdown.propTypes = {
  setIsLibrary: PropTypes.func,
  syncTemplate: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  convertTemplate: PropTypes.func,
  containsLibrarySlide: PropTypes.bool,
  currentTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
};

export default TemplateActionDropdown;
